import { Link } from "gatsby"
import React from "react"
import Logo from "./logo"
import styled from 'styled-components'
import media from "../media"
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Saturn from "../../images/saturn.svg"
import TheX from "../../images/theX.svg"


const StyledLogoContainer = styled.div`
${media.mobileS`
width:45%;
`}
width:20%;
`

const StyledMobileHeader = styled.div`
${media.mobileS`
z-index:1;
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
background-color:#212121;
height:100px;
position: fixed;
top: 0;
width: 100%;
padding:10% 5%;
`}
${media.laptop`
display:none;
`}
`

const StyledMobileNavLink = styled(Link)`
${media.tablet`
 font-size:0.8rem;
`}
color:black;
font-family:sans-serif;
text-transform:uppercase;
text-decoration:none;
`

const StyledMobileExternalNavLink = styled.a`
${media.tablet`
 font-size:0.8rem;
`}
color:black;
font-family:sans-serif;
text-transform:uppercase;
text-decoration:none;
`

const StyledMobileHeaderTopWrapper = styled.div`
z-index: 99 !important;
${media.mobileS`
display:block;
`}
${media.laptop`
display:none;
`}
`

const Header = () => {

const [anchorEl, setAnchorEl] = React.useState(null);

function handleClick(event) {
  setAnchorEl(event.currentTarget);
}

function handleClose() {
  setAnchorEl(null);
}

return (
<StyledMobileHeaderTopWrapper>
<StyledMobileHeader>
    <StyledLogoContainer>
    <Link to="/">
    <Logo/>
    </Link>
    </StyledLogoContainer>
    <div>
    <Button color="primary" variant="contained" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
      <MenuIcon/>
    </Button>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}><StyledMobileNavLink to="/temporal"><img src={Saturn} style={{width:"40px",margin:"0%",paddingRight:"4%"}}/>Temporal</StyledMobileNavLink></MenuItem>
      <MenuItem onClick={handleClose}><StyledMobileNavLink to="/temporalx"><img src={TheX} style={{width:"40px",margin:"0%",paddingRight:"4%" }}/>TemporalX</StyledMobileNavLink></MenuItem>
      <MenuItem onClick={handleClose}><StyledMobileNavLink to="/faq">FAQ</StyledMobileNavLink></MenuItem>
      <MenuItem onClick={handleClose}><StyledMobileExternalNavLink href="https://medium.com/temporal-cloud">Blog</StyledMobileExternalNavLink></MenuItem>
      <MenuItem onClick={handleClose}><StyledMobileNavLink to="/dev">Developers</StyledMobileNavLink></MenuItem>
      <MenuItem onClick={handleClose}><StyledMobileExternalNavLink href="https://play.temporal.cloud/">Playground</StyledMobileExternalNavLink></MenuItem>
      <MenuItem onClick={handleClose}><StyledMobileNavLink to="/contact">Contact</StyledMobileNavLink></MenuItem>
    </Menu>
    </div>
  </StyledMobileHeader>
</StyledMobileHeaderTopWrapper>

  );
}

export default Header





