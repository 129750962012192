import { Link } from "gatsby"
import React from "react"
import Logo from "./logo"
import styled from 'styled-components'
import media from "../media"
import Button from '@material-ui/core/Button'

import Saturn from "../../images/saturn.svg"
import TheX from "../../images/theX.svg"



const StyledHeader = styled.header`

display:flex;
flex-direction:row;
width:100%;
padding-top:4rem;
justify-content:space-around;
`

const StyledNavItemContainer = styled.ul`
align-items:flex-start;
display:flex;
justify-content:space-between;

padding-left:10%;
padding-top:1rem;
${media.laptop`
  width:60%;
`}
${media.laptopL`
  width:50%;
`}


`
const StyledNavItem = styled.li`
text-decoration:none;
font-family:sans-serif;
list-style:none;

`


const StyledNavLink = styled(Link)`


color:white;
font-family:sans-serif;
text-transform:uppercase;
text-decoration:none;
&:hover{
  color:orange;
}

${media.laptop`
font-size:0.6rem;
`}
${media.laptopL`
font-size:0.8rem;
`}
${media.desktop`
font-size:1.2rem;
`}
`


const StyledExternalNavLink = styled.a`
color:white;
font-family:sans-serif;
text-transform:uppercase;
text-decoration:none;
&:hover{
  color:orange;
}
${media.laptop`
font-size:0.6rem;
`}
${media.laptopL`
font-size:0.8rem;
`}
${media.desktop`
font-size:1.2rem;
`}
`
const StyledLogoContainer = styled.div`
width:20%;
`

const StyledHeaderTopWrapper = styled.div`

${media.mobileS`
display:none;
`}
${media.laptop`
display:block;
`}
`
const StyledPlaygroundLink = styled.a`
text-decoration:none;
color:white;
${media.laptop`
font-size:0.6rem;
`}
${media.laptopL`
font-size:0.8rem;
`}
${media.desktop`
font-size:1.2rem;
`}
`
const StyledDropdownButton = styled.a`
color:white;
font-family:sans-serif;
text-transform:uppercase;
text-decoration:none;
padding-bottom:10%;
${media.laptop`
font-size:0.6rem;
`}
${media.laptopL`
font-size:0.8rem;
`}
${media.desktop`
font-size:1.2rem;
`}
  &:hover {
      color: orange;
      }
`
const StyledDropdown = styled.div`

  &:hover {
      display: block;
      >div {
          display: flex;
          flex-direction:column;

      }
    }

    
  
`

const StyledDropdownContent = styled.div`
  display: none;
  position: absolute;
  /* transform: translateX(-90px); */
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius:5px;

  &:hover{
    background-color: #ddd;
}


`

const StyledDropdownNavLink = styled(Link)`

display:flex;
justify-content:flex-start;
align-items:center;
color:#212121;
font-family:sans-serif;
text-transform:uppercase;
text-decoration:none;
padding-bottom:5%;
padding-top:5%;
border-bottom:1px solid black;
padding-left:5%;
&:hover{
  color:orange;
}

${media.laptop`
font-size:0.6rem;
`}
${media.laptopL`
font-size:0.8rem;
`}
${media.desktop`
font-size:1.2rem;
`}
`

const StyledIcon = styled.span`
min-width:40px;
padding: 0 5%;
`



const Header = () => {

return (
<StyledHeaderTopWrapper>

  <StyledHeader>
  <StyledLogoContainer> 
    <Link to="/">
      <Logo/>
    </Link>
  </StyledLogoContainer>
  <StyledNavItemContainer>

    <StyledDropdown>
      <StyledDropdownButton>Products</StyledDropdownButton>
      <StyledDropdownContent>
        <StyledDropdownNavLink to="/temporal"><img src={Saturn} style={{width:"40px",margin:"0%",paddingRight:"2%"}}/>Temporal</StyledDropdownNavLink>
        <StyledDropdownNavLink to="/temporalx"><img src={TheX} style={{width:"40px",margin:"0%",paddingRight:"2%" }}/>TemporalX</StyledDropdownNavLink>
      </StyledDropdownContent>
    
    </StyledDropdown>

    <StyledNavItem><StyledNavLink to="/faq">FAQ</StyledNavLink></StyledNavItem>
    <StyledNavItem><StyledExternalNavLink href="https://medium.com/temporal-cloud">Blog</StyledExternalNavLink></StyledNavItem>
    <StyledNavItem><StyledNavLink to="/dev">Developers</StyledNavLink></StyledNavItem>
    <StyledNavItem><StyledNavLink to="/contact">Contact</StyledNavLink></StyledNavItem>
    <StyledNavItem><Button variant="outlined" color="primary" ><StyledPlaygroundLink  href="https://play.temporal.cloud" >Playground</StyledPlaygroundLink ></Button></StyledNavItem>
  </StyledNavItemContainer>
</StyledHeader>

</StyledHeaderTopWrapper>

  );
}

export default Header





